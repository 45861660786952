import React, { useState } from 'react';
import classNames from 'classnames';
import Format from 'types/format.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { CreativeEditorV2FormatActions } from '../../creative-editor/components/creative-editor-format-actions';
import { Item } from '..';

import '../styles/format.scss';

interface Props {
    item: Item;
    active: boolean;
    selectable?: boolean; // If this is true, each format will have a checkbox and there is an option to enable all formats at the same time
    selected?: boolean;
    onClickItem: (item: Item) => void;
    onSelectItem?: (item: Item) => void;
}

const FormatsListItem: React.FC<Props> = ({ item, selected, selectable, active, onClickItem, onSelectItem }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={classNames('bricks-formats-list-item', {
                'bricks-formats-list-item--selectable': selectable,
                'bricks-formats-list-item--active': active
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => onClickItem(item)}>
            {selectable && (
                <div className="bricks-formats-list-item__checkbox">
                    <Checkbox size="small" checked={selected} onChange={() => onSelectItem && onSelectItem(item)} />
                </div>
            )}
            <div className="bricks-formats-list-item__preview">
                <div className="bricks-formats-list-item__preview__placement" style={CreativeV2FormatHelpers.getFormatStyles(item as Format)}></div>
            </div>
            <div className="bricks-formats-list-item__title">{item.title}</div>
            <div className="bricks-formats-list-item__actions" onClick={(e) => e.stopPropagation()}>
                <CreativeEditorV2FormatActions formatKey={item.key} />
            </div>
        </div>
    );
};

export { FormatsListItem };
