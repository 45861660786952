import React from 'react';
import { FormatsListItem } from './format';
import { Item } from '..';
import '../styles/formats.scss';

interface Props {
    items: Item[];
    searchTerm: string;
    selectable?: boolean; // If this is true, each format will have a checkbox and there is an option to enable all formats at the same time
    selectedItems?: string[];
    activeItem?: string;
    filters?: string[];
    onClickItem: (item: Item) => void;
    onSelectItem: (item: Item) => void;
}

const FormatsListItems: React.FC<Props> = ({ items, selectable, selectedItems, activeItem, onClickItem, onSelectItem }) => {
    return (
        <div className="bricks-formats-list-items">
            {!items || (items.length === 0 && <div className="bricks-formats-list-items__empty">No formats</div>)}
            {items?.length > 0 &&
                items.map((item: Item) => (
                    <FormatsListItem
                        key={item.title}
                        item={item}
                        selected={selectedItems?.includes(item.key)}
                        selectable={selectable}
                        active={!!activeItem && activeItem === item.key}
                        onClickItem={onClickItem}
                        onSelectItem={onSelectItem}
                    />
                ))}
        </div>
    );
};

export { FormatsListItems };
