import React, { useRef, useState } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import UpdateIcon from '@mui/icons-material/Update';
import { Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { IframeSettings } from 'components/creatives-v2/creative-types/template-creative.class';
import cloneDeep from 'helpers/cloneDeep';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import EditorData from 'components/editor-data/EditorData';
import TemplateDesignerService from 'components/template-designer/services/template-designer.service';
import { CustomTemplateAsset } from 'components/template-management/types/template-management.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Button from 'components/ui-components-v2/Button';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import { CreativeV2TemplateEnriched } from '../../creative-editor/types/creativeV2.type';
import { AdobeRenderStatus } from '../types/adobe-status';
import '../styles/after-effects.scss';

// TODO Not finished yet, uses the same render function as after-effects. First version.

interface Props {
    format: string;
    creative: CreativeV2TemplateEnriched;
    data: IframeSettings<null>;
    scale?: number;
}

interface PreviewData {
    status: number;
    url?: string;
    thumbnail?: string;
    renderProgress: number;
    loading: boolean;
    statusMessage?: string;
}

const DynamicVideo = ({ format, data, creative, scale }: Props) => {
    const [previewData, setPreviewData] = useState<PreviewData | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    /**
     * Renders the video preview
     */
    /**
     * Renders the video preview
     */
    const renderPreview = async () => {
        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateId) as CustomTemplateAsset;
        const templateInput = creative.data.templateInput;

        if (!template) return;

        const publishMapping = template.data?.publishMapping;
        const affectivityNew: any = cloneDeep(publishMapping);

        if (publishMapping && templateInput && format) {
            EditorData.parseDataDeep(affectivityNew, {
                blockData: templateInput,
                comp: format
            });
        } else {
            // TODO Other cases here? Necessary?
        }

        affectivityNew.priority = 3;
        affectivityNew.template = template;

        if (template.data.settings?.version === 2) {
            affectivityNew.projectUrl = template.data.settings.projectSourceUrl;
            delete affectivityNew.token;

            const token = await TemplateDesignerService.getAfterEffectsToken();
            if (!token) {
                SnackbarUtils.error('Something went wrong while trying to render the video preview. Please try again later.');
                return;
            }

            const response = await TemplateDesignerService.startLegacyVideoRender(token, affectivityNew);
            if (!response) {
                sessionStorage.removeItem(TemplateDesignerService.AFTER_EFFECTS_TOKEN);
                SnackbarUtils.error('Something went wrong while trying to render the video preview. Please try again later.');
                return;
            }

            if (response.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE) {
                setPreviewData({
                    loading: false,
                    url: response.videoUrl,
                    thumbnail: response.stillUrl,
                    renderProgress: 100,
                    status: response.status
                });
                return;
            }

            setPreviewData({
                loading: true,
                url: response.videoUrl,
                thumbnail: response.stillUrl,
                renderProgress: 0,
                status: response.status
            });

            if (intervalRef.current) clearInterval(intervalRef.current);
            intervalRef.current = setInterval(() => checkIfRenderisComplete(response.uuid, token), 2000);
        } else {
            // TODO affectivity here? Is it necessary?
        }
    };

    /**
     * Checks if the render is complete. If it is, it will stop the interval and set the video url
     * if there render is not complete, it will update the render progress
     * if there is an error, it will stop the interval and show an error message
     *
     * @param uuid the uuid of the render job
     * @param token a auth token for the api call
     */
    const checkIfRenderisComplete = async (uuid: string, token: string) => {
        try {
            const response = await TemplateDesignerService.checkVideoRender(uuid, token);

            if (!response) return;
            if (response.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE) {
                if (intervalRef.current) clearInterval(intervalRef.current);
                setPreviewData({
                    ...previewData,
                    thumbnail: response.stillUrl,
                    url: response.videoUrl,
                    renderProgress: response.renderProgress,
                    status: response.status,
                    loading: false
                });

                setTimeout(() => {
                    // Start playing
                    if (videoRef.current) {
                        videoRef.current.play();
                    }
                }, 100);
                return;
            }
            setPreviewData({
                ...previewData,
                thumbnail: response.stillUrl,
                url: response.videoUrl,
                loading: true,
                renderProgress: response.renderProgress,
                status: response.status
            });

            if (response.status === AdobeRenderStatus.RENDER_STATUS_ERROR) {
                throw response;
            }
        } catch (error) {
            sessionStorage.removeItem(TemplateDesignerService.AFTER_EFFECTS_TOKEN);
            if (intervalRef.current) clearInterval(intervalRef.current);

            if (error instanceof AxiosError) {
                setPreviewData({
                    ...previewData,
                    loading: false,
                    status: error.response?.data.status || AdobeRenderStatus.RENDER_STATUS_ERROR,
                    renderProgress: 100
                });
                SnackbarUtils.error(error.response?.data.statusMessage);
                return;
            }

            setPreviewData({ ...previewData, loading: false, status: AdobeRenderStatus.RENDER_STATUS_ERROR, renderProgress: 100 });
            SnackbarUtils.error('Something went wrong with rendering the video');
        }
    };

    const downloadVideo = () => {
        if (previewData && previewData.url) window.open(previewData.url);
    };

    const scaledWidth = data.width * (scale || 1);
    const scaledHeight = data.height * (scale || 1);

    const renderStatus = previewData?.status || 0;
    const renderProgress = previewData?.renderProgress || 0;

    return (
        <div className="after-effects" style={{ width: scaledWidth, height: scaledHeight }}>
            {previewData && !previewData.loading && previewData.url && (
                <video className="after-effects__video" autoPlay={false} controls={true} ref={videoRef} muted src={previewData.url} />
            )}

            {(!previewData || (!previewData.loading && !previewData.url)) && (
                <div className="after-effects__empty-video">
                    <div className="after-effects__empty-video__button">
                        <Button variant="contained" color="primary" onClick={renderPreview}>
                            Render video preview
                        </Button>
                    </div>
                </div>
            )}

            {previewData &&
                previewData.url &&
                (previewData.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE || renderStatus === AdobeRenderStatus.RENDER_STATUS_COMPLETE) && (
                    <div className="after-effects__rerender-container">
                        <Tooltip title="Render new video">
                            <div className="after-effects__rerender-container__rerender-button" onClick={renderPreview}>
                                <UpdateIcon />
                            </div>
                        </Tooltip>
                        <Tooltip title="Download video">
                            <div className="after-effects__rerender-container__download-button" onClick={downloadVideo}>
                                <GetAppIcon />
                            </div>
                        </Tooltip>
                    </div>
                )}

            {previewData && previewData.loading && (
                <div className="after-effects__loading-container">
                    <div className="after-effects__loading-container__text">
                        {(() => {
                            if (renderStatus === AdobeRenderStatus.RENDER_STATUS_QUEUE) {
                                return 'Waiting in queue';
                            }

                            if (renderStatus === AdobeRenderStatus.RENDER_STATUS_RENDERING) {
                                return 'Rendering new video.\nThis may take a few minutes.';
                            }

                            return 'loading...';
                        })()}
                    </div>
                    {renderStatus >= 0 && (
                        <div className="after-effects__loading-container__spinner">
                            {renderStatus === 1 && renderProgress !== undefined ? (
                                <div className="after-effects__loading-container__progress">
                                    <LinearProgress className="after-effects__loading-container__progress-bar" variant="determinate" value={renderProgress} />
                                    {renderProgress}%
                                </div>
                            ) : (
                                <CircularProgress />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export { DynamicVideo };
